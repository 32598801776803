import { mdiHomeAnalytics } from "@mdi/js";
const generalNav = [
  {
    title: "Général",
    icon: mdiHomeAnalytics,

    children: [
      {
        title: "Dashboard",
        to: {
          name: "general-dashboard",
        },
        resource: "general",
        action: "manage",
      },
      {
        title: "GA par site",
        to: {
          name: "ga-sites-main",
        },
        resource: "general",
        action: "manage",
      },
    ],
  },
];

export default generalNav;
