const googleDiscoverRoutes = [
  {
    path: "/google-discover/insights",
    name: "google-discover-insights",
    component: () => import("@/views/google-discover/GDInsights.vue"),
    meta: {
      layout: "content",
      resource: "google_discover",
      action: "manage",
    },
  },
  {
    path: "/google-discover/republishing",
    name: "google-discover-republishing",
    component: () => import("@/views/google-discover/RepublishingDiscover.vue"),
    meta: {
      layout: "content",
      resource: "google_discover",
      action: "manage",
    },
  },
  {
    path: "/google-discover/usage-statistics",
    name: "google-discover-usage-statistics",
    component: () => import("@/views/google-discover/GDUsageStats.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/google-discover/republishing-stats",
    name: "google-discover-republishing-stats",
    component: () =>
      import("@/views/google-discover/RepublishingDiscoverStats.vue"),
    meta: {
      layout: "content",
      resource: "google_discover",
      action: "manage",
    },
  },
  {
    path: "/google-discover/real-time-dashboard",
    name: "google-discover-real-time-dashboard",
    component: () => import("@/views/google-discover/RealTimeDashboard.vue"),
    meta: {
      layout: "content",
      resource: "google_discover",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "google-discover-real-time-dashboard",
        eventCategory: "page-view",
      },
    },
  },
];

export default googleDiscoverRoutes;
