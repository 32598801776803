import axios from "@axios";

const createReportObject = (partners) => {
  const reportObject = {
    ca: { up: 0, down: 0, upT: 0, downT: 0 },
    sessions: { up: 0, down: 0, upT: 0, downT: 0 },
    rpm: { up: 0, down: 0, upT: 0, downT: 0 },
  };

  reportObject["partners"] = {};
  reportObject["partnersRpm"] = {};
  for (const { id } of partners) {
    reportObject["partners"][id] = {
      up: 0,
      down: 0,
      upT: 0,
      downT: 0,
    };
    reportObject["partnersRpm"][id] = {
      up: 0,
      down: 0,
      upT: 0,
      downT: 0,
    };
  }

  return reportObject;
};

export default {
  namespaced: true,
  state: {
    filterPeriods: [],
    filterSearch: "",
    filterExclude: "",
    usersList: [],
    partnersList: [],
    rangeType: "d1",
    currentId: -1,
    globalAlertConfig: null,
    caByPlatformModal: false,
    rpmByPlatformModal: false,
    platformsCopyModal: false,
    platformsCopyType: "ca",
  },
  getters: {
    getFilterPeriods: (state) => state.filterPeriods,
    getFilterSearch: (state) => state.filterSearch,
    getFilterExclude: (state) => state.filterExclude,
    getUsersList: (state) => state.usersList,
    getPartnersList: (state) => state.partnersList,
    getCurrentId: (state) => state.currentId,
    getRangeType: (state) => state.rangeType,
    getGlobalAlertConfig: (state) => state.globalAlertConfig,
    getCaByPlatformModal: (state) => state.caByPlatformModal,
    getRpmByPlatformModal: (state) => state.rpmByPlatformModal,
    getPlatformsCopyModal: (state) => state.platformsCopyModal,
    getPlatformsCopyType: (state) => state.platformsCopyType,
  },
  mutations: {
    setFilterPeriods(state, value) {
      state.filterPeriods = value;
    },
    setFilterSearch(state, value) {
      state.filterSearch = value;
    },
    setFilterExclude(state, value) {
      state.filterExclude = value;
    },
    setUsersList(state, value) {
      state.usersList = value;
    },
    setPartnersList(state, value) {
      state.partnersList = value;
    },
    setCurrentId(state, value) {
      state.currentId = value;
    },
    setRangeType(state, value) {
      state.rangeType = value;
    },
    setGlobalAlertConfig(state, value) {
      state.globalAlertConfig = value;
    },
    setCaByPlatformModal(state, value) {
      state.caByPlatformModal = value;
    },
    setRpmByPlatformModal(state, value) {
      state.rpmByPlatformModal = value;
    },
    setPlatformsCopyType(state, value) {
      state.platformsCopyType = value;
    },
    setPlatformsCopyModal(state, value) {
      state.platformsCopyModal = value;
    },
  },
  actions: {
    setCaByPlatformModal(state, value) {
      state.commit("setCaByPlatformModal", value);
    },
    setRpmByPlatformModal(state, value) {
      state.commit("setRpmByPlatformModal", value);
    },
    copyGlobalCA(state) {
      const id = state.getters.getCurrentId;
      const globalAlertConfig = state.getters.getGlobalAlertConfig;
      const rangeType = state.getters.getRangeType;
      const { up, down, upT, downT } = globalAlertConfig[id][rangeType].ca;
      for (const platformId in globalAlertConfig[id][rangeType].partners) {
        globalAlertConfig[id][rangeType].partners[platformId].up = up;
        globalAlertConfig[id][rangeType].partners[platformId].down = down;
        globalAlertConfig[id][rangeType].partners[platformId].upT = upT;
        globalAlertConfig[id][rangeType].partners[platformId].downT = downT;
      }
      state.commit("setGlobalAlertConfig", globalAlertConfig);
    },
    copyGlobalRPM(state) {
      const id = state.getters.getCurrentId;
      const globalAlertConfig = state.getters.getGlobalAlertConfig;
      const rangeType = state.getters.getRangeType;
      const { up, down, upT, downT } = globalAlertConfig[id][rangeType].rpm;
      for (const platformId in globalAlertConfig[id][rangeType].partnersRpm) {
        globalAlertConfig[id][rangeType].partnersRpm[platformId].up = up;
        globalAlertConfig[id][rangeType].partnersRpm[platformId].down = down;
        globalAlertConfig[id][rangeType].partnersRpm[platformId].upT = upT;
        globalAlertConfig[id][rangeType].partnersRpm[platformId].downT = downT;
      }
      state.commit("setGlobalAlertConfig", globalAlertConfig);
    },
    copyPlatformValues(state, type) {
      const id = state.getters.getCurrentId;
      const rangeType = state.getters.getRangeType;
      const globalAlertConfig = state.getters.getGlobalAlertConfig;

      if (type === "ca") {
        state.commit("setPlatformsCopyType", "ca");
        const partners = globalAlertConfig[id][rangeType].partners;
        for (const platformId in partners) {
          const currentPlatform = partners[platformId];
          if (
            currentPlatform.up !== 0 ||
            currentPlatform.down !== 0 ||
            currentPlatform.upT !== 0 ||
            currentPlatform.downT !== 0
          ) {
            state.dispatch("setPlatformsCopyModal", true);
            return;
          }
        }
        state.dispatch("copyGlobalCA");
      } else {
        state.commit("setPlatformsCopyType", "rpm");
        const partners = globalAlertConfig[id][rangeType].partnersRpm;
        for (const platformId in partners) {
          const currentPlatform = partners[platformId];
          if (
            currentPlatform.up !== 0 ||
            currentPlatform.down !== 0 ||
            currentPlatform.upT !== 0 ||
            currentPlatform.downT !== 0
          ) {
            state.dispatch("setPlatformsCopyModal", true);
            return;
          }
        }
        state.dispatch("copyGlobalRPM");
      }
    },
    setPlatformsCopyModal(state, value) {
      state.commit("setPlatformsCopyModal", value);
    },
    setFilterPeriods(state, value) {
      state.commit("setFilterPeriods", value);
    },
    setFilterSearch(state, value) {
      state.commit("setFilterSearch", value);
    },
    setFilterExclude(state, value) {
      state.commit("setFilterExclude", value);
    },
    async getPartnersList(state) {
      const {
        data: { items },
      } = await axios.get("/partner-views/");
      state.commit("setPartnersList", items);

      return items;
    },
    setRangeType(state, value) {
      state.commit("setRangeType", value);
    },
    setCurrentId(state, value) {
      state.commit("setCurrentId", value);
    },
    async getUsersList(state) {
      const { data } = await axios.get("/users/");
      state.commit("setUsersList", data);
    },
    async setInitialAlertConfig(state) {
      const {
        data: { items },
      } = await axios.get("/partner-views/");
      const partnersViews = items;

      const value = {};
      value[-1] = {
        name: "All Sites",
        d1: createReportObject(partnersViews),
        d7: createReportObject(partnersViews),
        d30: createReportObject(partnersViews),
      };
      state.commit("setGlobalAlertConfig", value);
    },
    async initGlobalAlertConfig(state, value) {
      // filter sites if sitesGroups are selected

      const {
        data: { allSites },
      } = await axios.get("/sites/", {
        params: { sort_by: "name" },
      });

      const siteList = [];
      if (value.groupSites.length > 0) {
        const { data } = await axios.get("/site-groups/sites", {
          params: { group_ids: value.groupSites.join(",") },
        });
        const siteIds = [...new Set(data.map((x) => x.site_id))];
        siteList = allSites.filter((site) => siteIds.includes(site.id));
        state.commit("setSiteList", siteList);
      }
      const values = state.getters.getGlobalAlertConfig;
      for (const site of allSites) {
        // clone object for each site (the only way to deep copy and not reference)
        values[site.id] = JSON.parse(JSON.stringify(values[-1]));
        values[site.id].name = site.name;
      }
      state.commit("setGlobalAlertConfig", values);
    },
    updateGlobalAlertConfig(state, { id, type, values }) {
      const config = state.getters.getGlobalAlertConfig;
      const categories = ["ca", "sessions", "rpm"];
      const directions = ["up", "down", "upT", "downT"];
      for (const category of categories) {
        for (const direction of directions) {
          values[category][direction] =
            parseFloat(values[category][direction]) || 0;
        }
      }
      config[id][type] = values;
      state.commit("setGlobalAlertConfig", config);
    },
  },
};
