import { mdiCalendar } from "@mdi/js";

const arbitrageNav = [
  {
    title: "Acquisition - Campagne",
    icon: mdiCalendar,
    children: [
      {
        title: "Campaign Creator",
        to: "campain-main",
        resource: "arbitrage_campaign",
        action: "manage",
      },
      {title: "Campaign Tracker",
        to: "campain-tracker",
        resource: "arbitrage_campaign",
        action: "manage",
       }
    ],
}];

export default arbitrageNav;



