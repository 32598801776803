const rmrAnalyticsRoutes = [
  {
    path: "/rmr-analytics/projects-list",
    name: "rmra-projects-list",
    component: () => import("@/views/rmr-analytics/ProjectsList.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/project-create/",
    name: "rmra-projects-create",
    component: () => import("@/views/rmr-analytics/ProjectsCreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/projects-edit/:id",
    name: "rmra-projects-edit",
    component: () => import("@/views/rmr-analytics/ProjectsCreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/event-types-list",
    name: "rmra-event-types-list",
    component: () => import("@/views/rmr-analytics/EventTypesList.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/event-types-create/",
    name: "rmra-event-types-create",
    component: () =>
      import("@/views/rmr-analytics/EventTypesCreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/event-types-edit/:id",
    name: "rmra-event-types-edit",
    component: () =>
      import("@/views/rmr-analytics/EventTypesCreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/event-list",
    name: "rmra-events-list",
    component: () => import("@/views/rmr-analytics/EventsList.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  // rmra event categories
  /*
  {
    path: "/rmr-analytics/event-categories-list",
    name: "rmra-event-categories-list",
    component: () => import("@/views/rmr-analytics/EventCategoriesList.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/event-categories-create/",
    name: "rmra-event-categories-create",
    component: () =>
      import("@/views/rmr-analytics/EventCategoriesCreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/rmr-analytics/event-categories-edit/:id",
    name: "rmra-event-categories-edit",
    component: () =>
      import("@/views/rmr-analytics/EventCategoriesCreateOrUpdate.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
   */
];

export default rmrAnalyticsRoutes;
