import axios from "@axios";

export default {
  namespaced: true,
  state: {
    partners: [],
    partnerViews: [],
    sites: [],
    siteGroups: [],
    brands: [],
    billings: [],
    cashings: [],
    partnerList: [],
    partnerCurrencyList: [],
    partnerNatureList: [],
    billingList: [],
    cashingList: [],
    monthDate: "",
  },
  getters: {
    getPartners: (state) => state.partners,
    getPartnerViews: (state) => state.partnerViews,
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,
    getBrands: (state) => state.brands,
    getBillings: (state) => state.billings,
    getCashings: (state) => state.cashings,
    getPartnerList: (state) => state.partnerList,
    getPartnerCurrencyList: (state) => state.partnerCurrencyList,
    getPartnerNatureList: (state) => state.partnerNatureList,
    getBillingList: (state) => state.billingList,
    getCashingList: (state) => state.cashingList,
    getMonthDate: (state) => state.monthDate,
  },
  mutations: {
    updatePartners(state, payload) {
      state.partners = payload;
    },
    updatePartnerViews(state, payload) {
      state.partnerViews = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateBrands(state, payload) {
      state.brands = payload;
    },
    updateBillings(state, payload) {
      state.billings = payload;
    },
    updateCashings(state, payload) {
      state.cashings = payload;
    },
    updatePartnerList(state, payload) {
      state.partnerList = payload;
    },
    updatePartnerCurrencyList(state, payload) {
      state.partnerCurrencyList = payload;
    },
    updatePartnerNatureList(state, payload) {
      state.partnerNatureList = payload;
    },
    updateBillingList(state, payload) {
      state.billingList = payload;
    },
    updateCashingList(state, payload) {
      state.cashingList = payload;
    },
    updateMonthDate(state, payload) {
      state.monthDate = payload;
    },
  },
  actions: {
    updatePartners({ commit }, payload) {
      commit("updatePartners", payload);
    },
    updatePartnerViews({ commit }, payload) {
      commit("updatePartnerViews", payload);
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const site_ids = data
        .map((x) => x["site_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });

      commit("updateSites", site_ids);

      return site_ids;
    },
    updateBrands({ commit }, payload) {
      commit("updateBrands", payload);
    },
    updateBillings({ commit }, payload) {
      commit("updateBillings", payload);
    },
    updateCashings({ commit }, payload) {
      commit("updateCashings", payload);
    },
    updateMonthDate({ commit }, payload) {
      commit("updateMonthDate", payload);
    },
    async getPartnerList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/full_list");
      commit("updatePartnerList", items);

      return items;
    },
    async getPartnerCurrencyList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/currencies");
      commit("updatePartnerCurrencyList", items);

      return items;
    },
    async getPartnerNatureList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/natures");
      commit("updatePartnerNatureList", items);

      return items;
    },
    async getBillingList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/billing_entities");
      commit("updateBillingList", items);

      return items;
    },
    async getCashingList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/cashing_entities");
      commit("updateCashingList", items);

      return items;
    },
  },
};
