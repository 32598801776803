const regieRoutes = [
  {
    path: "/regie/perf-per-article",
    name: "regie-perf",
    component: () => import("@/views/audience/PerfPerArticle.vue"),
    props: {
      department: "regie",
      logo: () => import("@/components/regie/Logo.vue"),
    },
    meta: {
      layout: "content",
      resource: "regie",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "regie-perf-per-article",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/regie/visitors-info",
    name: "regie-visitors",
    component: () => import("@/views/regie/VisitorsInfo.vue"),
    props: {
      department: "regie",
      logo: () => import("@/components/regie/Logo.vue"),
    },
    meta: {
      layout: "content",
      resource: "regie",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "regie-visitors-info",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/regie/forecast",
    name: "regie-forecast",
    component: () => import("@/views/regie/Forecast.vue"),
    meta: {
      layout: "content",
      resource: "regie",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "regie-forecast",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/regie/marmiton",
    name: "regie-marmiton",
    component: () => import("@/views/regie/Marmiton.vue"),
    props: {
      department: "regie",
      logo: () => import("@/components/regie/Logo.vue"),
    },
    meta: {
      layout: "content",
      resource: "regie",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "regie-marmiton",
        eventCategory: "page-view",
      },
    },
  },
];

export default regieRoutes;
