import axios, { cacheTime } from "@axios";

export default {
  namespaced: true,
  state: {
    monthDate: "",
    dates: [],
    sites: [],
    siteGroups: [],
    partnersList: [],
    partnersViews: [],
    partnersViewsList: [],
    partnersViewsGroups: [],
    partnersViewsGroupsList: [],
    groupCA: true,
  },
  getters: {
    getDates: (state) => {
      return state.dates;
    },
    getSites: (state) => {
      return state.sites;
    },
    getSiteGroups: (state) => {
      return state.siteGroups;
    },
    getPartnerViewsGroups: (state) => {
      return state.partnersViewsGroups;
    },
    getPartnerViews: (state) => {
      return state.partnersViews;
    },
    getPartnerViewsGroupsList: (state) => {
      return state.partnersViewsGroupsList;
    },
    getPartnerViewsList: (state) => {
      return state.partnersViewsList;
    },
    getPartnersList: (state) => {
      return state.partnersList;
    },
    getMonthDate: (state) => {
      return state.monthDate;
    },
    getGroupCA: (state) => {
      return state.groupCA;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updatePartnerViews(state, payload) {
      state.partnersViews = payload;
    },
    updatePartnerViewsGroups(state, payload) {
      state.partnersViewsGroups = payload;
    },
    updatePartnerViewsGroupsList(state, payload) {
      state.partnersViewsGroupsList = payload;
    },
    updatePartnerViewsList(state, payload) {
      state.partnersViewsList = payload;
    },
    updatePartnersList(state, payload) {
      state.partnersList = payload;
    },
    updateMonthDate(state, payload) {
      state.monthDate = payload;
    },
    updateGroupCA(state, payload) {
      state.groupCA = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      commit("updateDates", payload);
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get siteIds only and remove duplicates
      const siteIds = [...new Set(data.map((x) => x.site_id))].sort();
      commit("updateSites", siteIds);

      return siteIds;
    },
    async updatePartnerViewsGroups({ commit }, payload) {
      commit("updatePartnerViewsGroups", payload);
      if (!payload.length) {
        commit("updatePartnerViews", []);
        return;
      }
      const { data } = await axios.get("/partner-view-groups/partner-views", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const partner_views_ids = data
        .map((x) => x["partner_view_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });
      commit("updatePartnerViews", partner_views_ids);
      return partner_views_ids;
    },
    async getPartnerViewsGroupsList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partner-view-groups/", {
        params: { sort_by: "name" },
      });
      commit("updatePartnerViewsGroupsList", items);

      return items;
    },
    async getPartnerViewsList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partner-views/", {
        params: { sort_by: "name" },
      });
      commit("updatePartnerViewsList", items);

      return items;
    },
    async getPartnersList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/");
      commit("updatePartnersList", items);

      return items;
    },
    async getDetailedRevenue({ commit }, payload) {
      const { data } = await axios.get(
        "/programmatic/detailed-revenue-monthly-report",
        {
          params: payload,
          cache: {
            maxAge: cacheTime,
          },
        }
      );

      return data;
    },
    updateMonthDate({ commit }, payload) {
      commit("updateMonthDate", payload);
    },
    updatePartnerViews({ commit }, payload) {
      commit("updatePartnerViews", payload);
    },
    updateGroupCA({ commit }, payload) {
      commit("updateGroupCA", payload);
    },
  },
};
