import axios from "@axios";

export default {
  namespaced: true,
  state: {
    dates: [],
    eventTypes: [],
    events: [],
    filteredEvents: [],
    eventTypesList: [],
  },
  getters: {
    getDates: (state) => {
      return state.dates;
    },
    getEventTypes: (state) => {
      return state.eventTypes;
    },
    getEvents: (state) => {
      return state.events;
    },
    getFilteredEvents: (state) => {
      return state.filteredEvents;
    },
    getEventTypesList: (state) => {
      return state.eventTypesList;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateEventTypes(state, payload) {
      state.eventTypes = payload;
    },
    updateEvents(state, payload) {
      state.events = payload;
    },
    updateFilteredEvents(state, payload) {
      state.filteredEvents = payload;
    },
    updateEventTypesList(state, payload) {
      state.eventTypesList = payload;
    }
  },
  actions: {
    updateDates({ commit }, payload) {
      commit("updateDates", payload);
    },
    updateEventTypes({ commit }, payload) {
      commit("updateEventTypes", payload);
    },
    updateEvents({ commit }, payload) {
      commit("updateEvents", payload);
    },
    updateFilteredEvents({ commit }, payload) {
      commit("updateFilteredEvents", payload);
    },
    async updateEventTypesList({ commit }) {
      const url = "/rmra-event-types/";
      const response = await axios.get(url);
      commit("updateEventTypesList", response.data.items);
    },
  },
};
