import axios from "@axios";

export default {
  namespaced: true,
  state: {
    groups: [],
  },
  getters: {
    getGroups: (state) => {
      return state.groups;
    },
  },
  mutations: {
    updateGroups(state, payload) {
      state.groups = payload;
    },
  },
  actions: {
    updateGroups({ commit }, payload) {
      commit("updateGroups", payload);
    },
    async getGroups({ commit }) {
      const { data: { items }} = await axios.get("/adb_videos_list_groups/", {
        params: {
          //sort_by: "name"
        },
      });
      const results = items.map(item => ({
        ...item,
        text: `${item["id"]} - ${item["name"]}`,
      }));
      commit("updateGroups", results);

      return results;
    },
  },
};
