import get from "lodash/get";

export default {
  namespaced: true,
  state: {
    loadingUrls: {},
  },
  getters: {
    getLoadingUrls: (state) => {
      return state.loadingUrls;
    },
  },
  mutations: {
    setLoadingUrls: (state, { name, value }) => {
      const [page, method] = name.split(".");
      state.loadingUrls = {
        ...state.loadingUrls,
        [page]: {
          ...state.loadingUrls[page],
          [method]: value,
        },
      };
    },
  },
  actions: {
    setLoadingUrls: ({ commit, state }, { name, value }) => {
      if (typeof value === "function") {
        const currentValue = get(state.loadingUrls, name, undefined);
        commit("setLoadingUrls", {
          name,
          value: value(currentValue),
        });

        return;
      }

      commit("setLoadingUrls", {
        name,
        value,
      });
    },
  },
};
