import { mdiPodium } from "@mdi/js";

const audience = [
  {
    title: "Audience",
    icon: mdiPodium,
    children: [
      /* {
        title: "Par site / semaine",
        to: "audience-home",
        resource: "audience",
        action: "manage",
      }, */
      {
        title: "Audience par article",
        to: "audience-perf-article",
        resource: "audience",
        action: "manage",
      },
      {
        title: "Audience par catégorie",
        to: "audience-perf-category",
        resource: "audience",
        action: "manage",
      },
      {
        title: "Rentabilité par article",
        resource: "admin",
        action: "manage",
        children: [
          {
            title: "Set up rédacteur",
            to: "redac-setups",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Rentabilité par articles",
            to: "renta-articles",
            resource: "admin",
            action: "manage",
          },
        ],
      },
      {
        title: "Google Analytics 3",
        resource: "audience",
        action: "manage",
        children: [
          {
            title: "Vue globale",
            to: "audience-ua-global-month-counts",
            resource: "audience",
            action: "manage",
          },
          {
            title: "Audience par site",
            to: "audience-ua-site-sessions-views",
            resource: "audience",
            action: "manage",
          },
          {
            title: "Détail mensuel",
            to: "audience-ua-table-data",
            resource: "audience",
            action: "manage",
          },
        ],
      },
      {
        title: "ACPM",
        to: "audience-acpm",
        resource: "audience",
        action: "manage",
      },
      {
        title: "Audience par source",
        to: "audience-by-source",
        resource: "audience",
        action: "manage",
      },
    ],
  },
];

export default audience;
