import axios from "axios";
import Vue from "vue";
import router from "@/router";
import { setupCache } from "axios-cache-adapter";
import store from "@/store";

const apiUrl = localStorage.getItem("apiUrl") ?? process.env.VUE_APP_API_URL;

export const cache = setupCache({
  maxAge: 0,
  // debug: true,
  exclude: {
    query: false,
  },
});

export const cacheTime = parseInt(
  localStorage.getItem("cacheTime") ?? process.env.VUE_APP_CACHE_TIME
);

const axiosIns = axios.create({
  baseURL: apiUrl,
  adapter: cache.adapter,
});

const refresh = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    const { data } = await axios.post(
      process.env.VUE_APP_API_URL + "users/refresh",
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    localStorage.setItem("accessToken", data["access_token"]);
    localStorage.setItem("refreshToken", data["refresh_token"]);
  } catch (err) {
    await router.push({ name: "auth-login" });
  }
};

axiosIns.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      ((error.response.status === 401 && !originalRequest._retry) ||
        (error.response.status === 403 && !originalRequest._retry))
    ) {
      originalRequest._retry = true;
      await refresh();
      const accessToken = localStorage.getItem("accessToken");
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      return axiosIns(originalRequest);
    } else if (error.response && error.response.status === 404) {
      // if element is not found do nothing, no need for authentication
      return error;
    } else if (error.request) {
      await store.dispatch("app/setSnackBar", {
        show: true,
        text: `Une erreur est survenue. Veuillez contactez l'administrateur.`,
        color: "error",
        timeout: 2000,
      });
    } else {
      await router.push({ name: "auth-login" });
    }
    return Promise.reject(error);
  }
);

axiosIns.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
