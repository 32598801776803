const devRoutes = [
  {
    path: "/dev/troubleshooting",
    name: "Troubleshooting",
    component: () => import("@/views/pages/dev/Troubleshooting.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/dev/crons",
    name: "crons",
    component: () => import("@/views/pages/dev/Crons.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/dev/n-days",
    name: "n-days",
    component: () => import("@/views/pages/dev/NDays.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/dev/repeat-replace",
    name: "repeat-replace",
    component: () => import("@/views/pages/dev/RepeatReplace.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/dev/copy-paste",
    name: "copy-paste",
    component: () => import("@/views/pages/dev/CopyPaste.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
];

export default devRoutes;
