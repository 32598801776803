const userRoutes = [
  // Permissions
  {
    path: "/users/permissions/create",
    name: "permissions-create",
    component: () => import("@/views/admin/users/permissions/PermissionCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/users/permissions/edit/:id",
    name: "permissions-edit",
    component: () => import("@/views/admin/users/permissions/PermissionCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/users/permissions",
    name: "permissions-list",
    component: () => import("@/views/admin/users/permissions/PermissionList"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  // Roles
  {
    path: "/users/roles/create",
    name: "roles-create",
    component: () => import("@/views/admin/users/roles/RoleCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/users/roles/edit/:id",
    name: "roles-edit",
    component: () => import("@/views/admin/users/roles/RoleCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/users/roles",
    name: "roles-list",
    component: () => import("@/views/admin/users/roles/RoleList"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  // Users
  {
    path: "/users/create",
    name: "users-create",
    component: () => import("@/views/admin/users/users/UserCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/users/edit/:id",
    name: "users-edit",
    component: () => import("@/views/admin/users/users/UserCreateOrUpdate"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/users",
    name: "users-list",
    component: () => import("@/views/admin/users/users/UserList"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
];
export default userRoutes;
