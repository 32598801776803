import { mdiGoogle } from "@mdi/js";

const googleDiscoverNav = [
  {
    title: "Google Discover",
    icon: mdiGoogle,
    children: [
      {
        title: "Google Discover Insights",
        to: "google-discover-insights",
        resource: "google_discover",
        action: "manage",
      },
      {
        title: "Republishing Discover",
        to: "google-discover-republishing",
        resource: "google_discover",
        action: "manage",
      },
      {
        title: "Usage Statistics",
        to: "google-discover-usage-statistics",
        resource: "all",
        action: "manage",
      },
      {
        title: "Republishing Discover Stats",
        to: "google-discover-republishing-stats",
        resource: "google_discover",
        action: "manage",
      },
      {
        title: "Google Discover Feed",
        to: "google-discover-real-time-dashboard",
        resource: "google_discover",
        action: "manage",
      },
    ],
  },
];

export default googleDiscoverNav;
