import "@/plugins/vue-composition-api";
import "@/styles/styles.scss";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./plugins/acl";

Vue.config.productionTip = false;
Vue.prototype.$labels = {
  ca: "CA généré",
  commissions: "Commissions",
  sells: "Ventes",
  clicks: "Clics",
  sessions: "Sessions",
};

Vue.prototype.$appToken = "appToken";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
