const ctcRoutes = [
  {
    path: "/content-to-commerce/dashboard",
    name: "c2c-dashboard",
    component: () => import("@/views/c2c/Dashboard.vue"),
    //component: () => import("@/views/pages/c2c/Index.vue"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "ctc-dashboard",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/content-to-commerce/sites-matching/:id",
    name: "c2c-admin-edit-site-matching",
    component: () => import("@/views/pages/admin/site/SiteEditMatching"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
    },
  },
  {
    path: "/content-to-commerce/sites-matching",
    name: "c2c-admin-sites-matching",
    component: () => import("@/views/pages/admin/site/SitesMatching"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
    },
  },
  /*
  {
    path: "/content-to-commerce/sites",
    name: "c2c-sites",
    component: () => import("@/views/pages/c2c/SiteList.vue"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
    },
  },
  {
    path: "/content-to-commerce/create-post",
    name: "c2c-post-create",
    component: () => import("@/views/pages/c2c/PostCreate.vue"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
    },
  },
  {
    path: "/content-to-commerce/list-post",
    name: "c2c-post-list",
    component: () => import("@/views/pages/c2c/PostList.vue"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
    },
  },
  {
    path: "/content-to-commerce/filled-dates",
    name: "c2c-filled-dates",
    component: () => import("@/views/pages/c2c/FilledDates.vue"),
    meta: {
      layout: "content",
      resource: "all",
      action: "manage",
    },
  },
  {
    path: "/content-to-commerce/missing-clicrefs",
    name: "c2c-missing-clicrefs",
    component: () => import("@/views/pages/c2c/MissingClicrefs.vue"),
    meta: {
      layout: "content",
      resource: "ctc",
      action: "manage",
    },
  },
  */
]

export default ctcRoutes;
