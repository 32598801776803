import { mdiCurrencyEur } from "@mdi/js";

const Nav = [
  {
    title: "Conso financière",
    icon: mdiCurrencyEur,
    resource: "finance",
    action: "manage",
    to: "financial-consolidation-report",
    // children: [
    //   {
    //     title: "Rapport mensuel",
    //     resource: "finance",
    //     action: "manage",
    //     to: "financial-monthly-report",
    //   },
    // ],
  },
];

export default Nav;
