import preset from "@/@core/preset/preset";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/lib/locale/fr";
import en from "vuetify/lib/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
  lang: {
    locales: { fr, en },
    current: "fr",
  },
});
