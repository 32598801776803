import { mdiPen } from "@mdi/js";

const seoNav = [
  {
    title: "SEO",
    icon: mdiPen,
    children: [
      {
        title: "SEO Semantic Tool",
        resource: "seo",
        action: "manage",
        children: [
          {
            title: "Formulaire",
            to: "semantic-tools",
            resource: "seo",
            action: "manage",
          },
          {
            title: "Data Site",
            to: "semantic-tools-display",
            resource: "seo",
            action: "manage",
          },
          {
            title: "Top 10 Urls",
            to: "semantic-tools-top-10",
            resource: "seo",
            action: "manage",
          },
          {
            title: "Quickiwin",
            to: "semantic-tools-quickwin",
            resource: "seo",
            action: "manage",
          },
        ],
      },
      {
        title: "SEO suivi P1",
        children: [
          {
            title: "Suivi P1 site",
            to: "seo-suivi-p1",
            resource: "seo",
            action: "manage",
          },
        ]
      },
    ]
  },
];

export default seoNav;
