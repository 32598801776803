<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <v-snackbar
      v-model="snackBar['show']"
      :timeout="snackBar['timeout']"
      :color="snackBar['color']"
      top
    >
      {{ snackBar["text"] }}
    </v-snackbar>
    <v-dialog v-model="dialog['show']" :width="dialog['width']">
      <template v-slot:activator="{ on, attrs }"></template>
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          v-html="dialog['title']"
        ></v-card-title>
        <v-card-text>
          <div v-html="dialog['text']" class="mb-4"></div>
          <pre
            v-show="dialog['code'] != ''"
            style="width: 100%; overflow-x: auto"
            v-html="dialog['code']"
          ></pre>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="dialog['type'] == 'confirm'">
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            @click="
              dialog['callbackFunction']();
              dialog['show'] = false;
            "
          >
            Confirmer
          </v-btn>
          <v-btn color="green darken-1" @click="dialog['show'] = false">
            Abandonner
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog['show'] = false">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <slot></slot>

    <!-- Slot: Navbar -->
    <template
      #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }"
    >
      <div
        class="navbar-content-container"
        :class="{ 'expanded-search': shallShowFullSearch }"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="
              handleShallShowFullSearchUpdate(
                isVerticalNavMenuActive,
                toggleVerticalNavMenuActive
              )
            "
          ></app-bar-search>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-user-notifications></app-bar-user-notifications>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="footer-links">
        <span
          >COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a
            href="https://www.reworldmedia.com/"
            target="_blank"
            class="text-decoration-none"
            >Reworld Media</a
          ><span class="d-none d-md-inline">, All rights Reserved</span></span
        >
        |
        <a class="text-decoration-none" href="/privacy-policy"
          >Privacy Policy</a
        >
        |
        <a class="text-decoration-none" href="/public-terms-of-service"
          >Public Terms of Service</a
        >
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from "@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue";
import navMenuItems from "@/navigation/vertical";

// App Bar Components
import AppBarSearch from "@core/layouts/components/app-bar/AppBarSearch.vue";
import AppBarUserMenu from "@/components/AppBarUserMenu.vue";
import AppBarUserNotifications from "@/components/AppBarUserNotifications.vue";

import { mdiMenu, mdiHeartOutline } from "@mdi/js";

import { getVuetify } from "@core/utils";

// Search Data
import appBarSearchData from "@/assets/app-bar-search-data";

import { computed, ref, watch } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils";

export default {
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
    AppBarUserNotifications,
  },
  setup() {
    const $vuetify = getVuetify();

    const snackBar = computed(() => store.getters["app/getSnackBar"]);
    const dialog = computed(() => store.getters["app/getDialog"]);

    // Search
    const appBarSearchQuery = ref("");
    const shallShowFullSearch = ref(false);
    const maxItemsInGroup = 5;
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    });
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      };
    });

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true;

      const itemGroup = (() => {
        if (item.to !== undefined) return "pages";
        if (item.size !== undefined) return "files";
        if (item.email !== undefined) return "contacts";

        return null;
      })();

      const isMatched =
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1;

      if (isMatched) {
        if (itemGroup === "pages") totalItemsInGroup.value.pages += 1;
        else if (itemGroup === "files") totalItemsInGroup.value.files += 1;
        else if (itemGroup === "contacts")
          totalItemsInGroup.value.contacts += 1;
      }

      return (
        appBarSearchQuery.value &&
        isMatched &&
        totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
      );
    };

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (
      isVerticalNavMenuActive,
      toggleVerticalNavMenuActive
    ) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive();
      }
    };

    return {
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,
      snackBar,
      dialog,

      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role="combobox"] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
.footer-links > :first-child {
  margin-right: 60px;
}
.footer-links > *:not(:first-child) {
  margin: 60px;
}
</style>
