import axios from "@axios";

export default {
  namespaced: true,
  state: {
    applyFilters: false,
    loading: false,
    categoryList: [],
    siteAreaList: [],
    siteBrandList: [],
    siteGroupList: [],
    siteList: [],
    partnerList: [],
    partnerViewList: [],
    redac: [],
  },
  getters: {
    getApplyFilters: (state) => state.applyFilters,
    getLoading: (state) => state.loading,
    getCategoryList: (state) => state.categoryList,
    getSiteAreaList: (state) => state.siteAreaList,
    getSiteBrandList: (state) => state.siteBrandList,
    getSiteGroupList: (state) => state.siteGroupList,
    getSiteList: (state) => state.siteList,
    getPartnerList: (state) => state.partnerList,
    getPartnerViewList: (state) => state.partnerViewList,
    getRedacList: (state) => state.redac,
  },
  mutations: {
    updateApplyFilters(state, payload) {
      state.applyFilters = payload;
    },
    updateLoading(state, payload) {
      state.loading = payload;
    },
    updateCategoryList(state, payload) {
      state.categoryList = payload;
    },
    updateSiteAreaList(state, payload) {
      state.siteAreaList = payload;
    },
    updateSiteBrandList(state, payload) {
      state.siteBrandList = payload;
    },
    updateSiteGroupList(state, payload) {
      state.siteGroupList = payload;
    },
    updateSiteList(state, payload) {
      state.siteList = payload;
    },
    updatePartnerList(state, payload) {
      state.partnerList = payload;
    },
    updatePartnerViewList(state, payload) {
      state.partnerViewList = payload;
    },
    updateRedacList(state, payload) {
      state.redac = payload;
    },
  },
  actions: {
    updateApplyFilters({ commit }, payload) {
      if (payload === false) {
        setTimeout(() => commit("updateApplyFilters", payload), 1000);
      } else {
        commit("updateApplyFilters", payload);
      }
    },
    updateLoading({ commit }, payload) {
      commit("updateLoading", payload);
    },
    async getCategoryList({ commit }, payload) {
      const {
        data: { items },
      } = await axios.get("/adn-post/categories", {
        params: payload,
      });
      commit("updateCategoryList", items);

      return items;
    },
    async getSiteAreaList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/sites/areas/");
      commit("updateSiteAreaList", items);

      return items;
    },
    async getSiteBrandList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/sites/brands/");
      commit("updateSiteBrandList", items);

      return items;
    },
    async getSiteGroupList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/site-groups/", {
        params: { sort_by: "name" },
      });
      commit("updateSiteGroupList", items);

      return items;
    },
    async getSiteList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/sites/", {
        params: { sort_by: "name" },
      });
      commit("updateSiteList", items);

      return items;
    },
    async getPartnerList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/", {
        params: { order_by: "name" },
      });
      commit("updatePartnerList", items);

      return items;
    },
    async getRedacList({ commit }) {
      const items = await axios.get("/redacs/get_recent_redacs"); // possibility to put a limit if needed  {params: { limit: None }}
      commit("updateRedacList", items.data.res);

      return items.data.res;
    },
    async getPartnerViewList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partner-views/", {
        params: { order_by: "label" },
      });
      commit("updatePartnerViewList", items);

      return items;
    },
  },
};
