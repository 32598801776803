import axios from "@axios";

export default {
  namespaced: true,
  state: {
    sites: [],
    siteGroups: [],

    // VisitorsData :
    containsText: "",
    period: 0,
    flavour: "",
    region: "",
    ageSlot: "",

    // Marmiton :
    marmitonPeriod: 0,
    marmitonDates: [],
    marmitonIngredientsToInclude: [],
    marmitonIngredientsToExclude: [],
    marmitonTopIngredientsToExclude: [],
    marmitonStatsForPeriod: {},
    marmitonSearchText: "",
  },
  getters: {
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,

    // VisitorsData :
    getContainsText: (state) => state.containsText,
    getPeriod: (state) => state.period,
    getFlavour: (state) => state.flavour,
    getRegion: (state) => state.region,
    getAgeSlot: (state) => state.ageSlot,

    // Marmiton :
    getMarmitonPeriod: (state) => state.marmitonPeriod,
    getMarmitonDates: (state) => {
      // always return the dates ordered
      return state.marmitonDates[0] < state.marmitonDates[1]
        ? [state.marmitonDates[0], state.marmitonDates[1]]
        : [state.marmitonDates[1], state.marmitonDates[0]];
    },
    getMarmitonIngredientsToInclude: (state) => {
      return state.marmitonIngredientsToInclude;
    },
    getMarmitonIngredientsToExclude: (state) => {
      return state.marmitonIngredientsToExclude;
    },
    getMarmitonTopIngredientsToExclude: (state) => {
      return state.marmitonTopIngredientsToExclude;
    },
    getMarmitonStatsForPeriod: (state) => {
      return state.marmitonStatsForPeriod;
    },
    getMarmitonSearchText: (state) => {
      return state.marmitonSearchText;
    },
  },
  mutations: {
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },

    // VisitorsData :
    updateContainsText(state, payload) {
      state.containsText = payload;
    },
    updatePeriod(state, payload) {
      state.period = payload;
    },
    updateFlavour(state, payload) {
      state.flavour = payload;
    },
    updateRegion(state, payload) {
      state.region = payload;
    },
    updateAgeSlot(state, payload) {
      state.ageSlot = payload;
    },

    // Marmiton :
    updateMarmitonPeriod(state, payload) {
      state.marmitonPeriod = payload;
    },
    updateMarmitonDates(state, payload) {
      state.marmitonDates = payload;
    },
    updateMarmitonIngredientsToInclude(state, payload) {
      state.marmitonIngredientsToInclude = payload;
    },
    updateMarmitonIngredientsToExclude(state, payload) {
      state.marmitonIngredientsToExclude = payload;
    },
    updateMarmitonTopIngredientsToExclude(state, payload) {
      state.marmitonTopIngredientsToExclude = payload;
    },
    updateMarmitonStatsForPeriod(state, payload) {
      state.marmitonStatsForPeriod = payload;
    },
    updateMarmitonSearchText(state, payload) {
      state.marmitonSearchText = payload;
    },
  },
  actions: {
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const site_ids = data
        .map((x) => x["site_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });

      commit("updateSites", site_ids);

      return site_ids;
    },

    // VisitorsData :
    updateContainsText({ commit }, payload) {
      commit("updateContainsText", payload);
    },
    updatePeriod({ commit }, payload) {
      commit("updatePeriod", payload);
    },
    updateFlavour({ commit }, payload) {
      commit("updateFlavour", payload);
    },
    updateRegion({ commit }, payload) {
      commit("updateRegion", payload);
    },
    updateAgeSlot({ commit }, payload) {
      commit("updateAgeSlot", payload);
    },

    // Marmiton :
    updateMarmitonPeriod({ commit }, payload) {
      commit("updateMarmitonPeriod", payload);
    },
    updateMarmitonDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateMarmitonDates", payload);
      }
    },
    updateMarmitonIngredientsToInclude({ commit }, payload) {
      commit("updateMarmitonIngredientsToInclude", payload);
    },
    updateMarmitonIngredientsToExclude({ commit }, payload) {
      commit("updateMarmitonIngredientsToExclude", payload);
    },
    updateMarmitonTopIngredientsToExclude({ commit }, payload) {
      commit("updateMarmitonTopIngredientsToExclude", payload);
    },
    updateMarmitonStatsForPeriod({ commit }, payload) {
      commit("updateMarmitonStatsForPeriod", payload);
    },
    updateMarmitonSearchText({ commit }, payload) {
      commit("updateMarmitonSearchText", payload);
    },
  },
};
