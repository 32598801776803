import { mdiFinance } from "@mdi/js";

const rmrnAnalyticsNav = [
  {
    title: "RMR Analytics",
    resource: "all",
    icon: mdiFinance,
    action: "manage",
    children: [
      {
        title: "Liste des projets",
        to: "rmra-projects-list",
        resource: "all",
        action: "manage",
      },
      {
        title: "Créer un nouveau projet",
        to: "rmra-projects-create",
        resource: "all",
        action: "manage",
      },
      {
        title: "Liste des types d'événements",
        to: "rmra-event-types-list",
        resource: "all",
        action: "manage",
      },
      {
        title: "Créer un nouveau type d'événement",
        to: "rmra-event-types-create",
        resource: "all",
        action: "manage",
      },
      {
        title: "Événements en temps réel",
        to: "rmra-events-list",
        resource: "all",
        action: "manage",
      },
    ],
  },
];

export default rmrnAnalyticsNav;
