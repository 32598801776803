const seoRoutes = [
  {
    path: "/seo/semantic-tools/formulaire",
    name: "semantic-tools",
    component: () => import("@/views/seo/Index.vue"),
    meta: {
      layout: "content",
      resource: "seo",
      action: "manage",
    },
  },
  {
    path: "/seo/semantic-tools/display",
    name: "semantic-tools-display",
    component: () => import("@/views/seo/Display.vue"),
    meta: {
      layout: "content",
      resource: "seo",
      action: "manage",
    },
  },
  {
    path: "/seo/semantic-tools/top10Urls",
    name: "semantic-tools-top-10",
    component: () => import("@/views/seo/Top10.vue"),
    meta: {
      layout: "content",
      resource: "seo",
      action: "manage",
    },
  },
  {
    path: "/seo/semantic-tools/quickwin",
    name: "semantic-tools-quickwin",
    component: () => import("@/views/seo/QuickWin.vue"),
    meta: {
      layout: "content",
      resource: "seo",
      action: "manage",
    },
  },
  {
    path: "/seo/seo-P1/suivi-p1",
    name: "seo-suivi-p1",
    component: () => import("@/views/seo/seo-P1/Index.vue"),
    meta: {
      layout: "content",
      resource: "seo",
      action: "manage",
    },
  },
];

export default seoRoutes;
