import axios from "@axios";

export default {
  namespaced: true,
  state: {
    dates: [],
    sites: [],
    dealsFilter: "nodeals",
    siteGroups: [],
    redacList: [],
    platforms: [],
    platformsStr: "",
    platformsList: [],
    programs: [],
    programsList: [],
    categories: [],
    categoriesList: [],
  },
  getters: {
    getDates: (state) => {
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },
    getDealsFilter: (state) => {
      return state.dealsFilter;
    },
    getSites: (state) => {
      return state.sites;
    },
    getSiteGroups: (state) => {
      return state.siteGroups;
    },
    getRedacList: (state) => {
      return state.redacList;
    },
    getPlatforms: (state) => {
      return state.platforms;
    },
    getPlatformsStr: (state) => {
      return state.platformsStr;
    },
    getPlatformsList: (state) => {
      return state.platformsList;
    },
    getPrograms: (state) => {
      return state.programs;
    },
    getProgramsList: (state) => {
      return state.programsList;
    },
    getCategories: (state) => {
      return state.categories;
    },
    getCategoriesList: (state) => {
      return state.categoriesList;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateDealsFilter(state, payload) {
      state.dealsFilter = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateRedacList(state, payload) {
      state.redacList = payload;
    },
    updatePlatforms(state, payload) {
      state.platforms = payload;
      let orderedP = [...payload].sort();
      if (orderedP.length > 0) {
        const maxPlatforms = orderedP[orderedP.length - 1];
        const platformsArr = Array(maxPlatforms).fill("_");
        for (const p of orderedP) {
          platformsArr[p - 1] = "X";
        }
        state.platformsStr = platformsArr.join("");
      } else {
        state.platformsStr = "";
      }
    },
    updatePlatformsList(state, payload) {
      state.platformsList = payload;
    },
    updatePrograms(state, payload) {
      state.programs = payload;
    },
    updateProgramsList(state, payload) {
      state.programsList = payload;
    },
    updateCategories(state, payload) {
      state.categories = payload;
    },
    updateCategoriesList(state, payload) {
      state.categoriesList = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updateDealsFilter({ commit }, payload) {
      commit("updateDealsFilter", payload);
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    updatePlatforms({ commit }, payload) {
      commit("updatePlatforms", payload);
    },
    updatePrograms({ commit }, payload) {
      commit("updatePrograms", payload);
    },
    updateCategories({ commit }, payload) {
      commit("updateCategories", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const site_ids = data
        .map((x) => x["site_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });

      commit("updateSites", site_ids);

      return site_ids;
    },
    async getRedacList({ commit }) {
      const { data } = await axios.get("/redacs/");
      commit("updateRedacList", data);

      return data;
    },
    async getPlatformsList({ commit, getters }) {
      if (getters.getPlatformsList.length === 0) {
        const { data } = await axios.get("/platforms/");
        commit("updatePlatformsList", data);
      }

      return getters.getPlatformsList;
    },
    async getCategoriesList({ commit, getters }) {
      const { data } = await axios.get("/categories/");
      commit("updateCategoriesList", data);

      return getters.getCategoriesList;
    },
    async getProgramsList({ commit, getters }) {
      if (getters.getProgramsList.length === 0) {
        const { data } = await axios.get("/programs/");
        commit("updateProgramsList", data);
      }

      return getters.getProgramsList;
    },
  },
};
