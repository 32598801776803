import { mdiGraphql } from "@mdi/js";

/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Pages' },

  { title: 'Content to Commerce - Dashboard', icon: mdiGraphql, to: {name: "c2c-dashboard"} },
  { title: 'Content to Commerce - Sites', icon: mdiGraphql, to: {name: "c2c-sites"} },
  { title: 'Content to Commerce - Créer un Article', icon: mdiGraphql, to: {name: "c2c-post-create"} },
  { title: 'Content to Commerce - Liste des Articles', icon: mdiGraphql, to: {name: "c2c-post-list"} },

]
