import axios from "@axios";

export default {
    namespaced: true,
    state: {
        redacs: [],
        sites: [],
        periods: [],
        redacsSetup: [],
    },
    getters: {
        getRedacs: (state) => {
            return state.redacs;
        },
        getSites: (state) => {
            return state.sites;
        },
        getPeriods: (state) => {
            return state.periods;
        },
        getRedacsSetup: (state) => {
            return state.RedacsSetup;
        }
    },
    mutations: {
        setRedacs(state, payload) {
            state.redacs = payload;
        },
        setSites(state, payload) {
            state.sites = payload;
        },
        setPeriods(state, payload) {
            state.periods = payload;
        },
        setRedacsSetup(state, payload) {
            state.redacsSetup = payload;
        }
    },
    actions: {
        setRedacs(context, payload) {
            context.commit("setRedacs", payload);
        },
        setSites(context, payload) {
            context.commit("setSites", payload);
        },
        setPeriods(context, payload) {
            context.commit("setPeriods", payload);
        },
        setRedacsSetup(context, payload) {
            context.commit("setRedacsSetup", payload);
        },
    },
}