import { mdiCupcake } from "@mdi/js";

const elior = [
  {
    title: "Elior",
    icon: mdiCupcake,
    children: [
      // {
      //   title: "Régie",
      //   to: "marmiton-regie",
      //   resource: "marmiton",
      //   action: "manage",
      // },
      {
        title: "Infos visiteurs",
        to: "marmiton-visitors",
        resource: "marmiton",
        action: "manage",
      },
    ],
  },
];

export default elior;
