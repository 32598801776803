<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        {{ icons.mdiBellOutline }}
      </v-icon>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item class="d-flex" link>
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">Rapports</span>
              <v-chip
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ count_unread }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <template v-for="(report, index) in reports">
            <v-list-item :key="report.title" link>
              <!-- Content -->
              <v-list-item-content class="d-block">
                <router-link
                  :to="report.url"
                  style="text-decoration: none; color: inherit"
                >
                  <v-list-item-title
                    class="text-sm"
                    :class="{ 'font-weight-semibold': !report.read_status }"
                  >
                    {{ report.name }}
                  </v-list-item-title>
                </router-link>
              </v-list-item-content>

              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ report.date }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <v-list-item key="read-all-btn" class="read-all-btn-list-item">
            <v-btn
              block
              color="primary"
              @click="$router.push('/alert-system/')"
            >
              Voir tous mes rapports
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline } from "@mdi/js";
import { getInitialName } from "@core/utils";

// 3rd Party
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import axios from "@axios";

export default {
  components: {
    PerfectScrollbar,
  },
  async created() {
    let { reports, count_unread } = await this.getReports();
    this.reports = reports;
    this.count_unread = count_unread;
  },
  data() {
    return {
      perfectScrollbarOptions: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },

      icons: {
        mdiBellOutline,
      },
      reports: [],
      count_unread: 0,
    };
  },
  methods: {
    getInitialName,
    async getReports() {
      const reportsData = await axios.get("/alert-system-generated-reports/", {
        params: {
          limit: 5,
        },
      });
      const reports = reportsData.data.reports;
      const count_unread = reportsData.data.count_unread;
      reports.map((report) => {
        report.url = `/alert-system/${report.id}/`;
      });
      return { reports, count_unread };
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
</style>
