var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content-vertical-nav',{attrs:{"nav-menu-items":_vm.navMenuItems},scopedSlots:_vm._u([{key:"navbar",fn:function(ref){
var isVerticalNavMenuActive = ref.isVerticalNavMenuActive;
var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive;
return [_c('div',{staticClass:"navbar-content-container",class:{ 'expanded-search': _vm.shallShowFullSearch }},[_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',{staticClass:"me-3",on:{"click":toggleVerticalNavMenuActive}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")]):_vm._e(),_c('app-bar-search',{attrs:{"shall-show-full-search":_vm.shallShowFullSearch,"data":_vm.appBarSearchData,"filter":_vm.searchFilterFunc,"search-query":_vm.appBarSearchQuery},on:{"update:shallShowFullSearch":[function($event){_vm.shallShowFullSearch=$event},function($event){return _vm.handleShallShowFullSearchUpdate(
              isVerticalNavMenuActive,
              toggleVerticalNavMenuActive
            )}],"update:shall-show-full-search":function($event){_vm.shallShowFullSearch=$event},"update:searchQuery":function($event){_vm.appBarSearchQuery=$event},"update:search-query":function($event){_vm.appBarSearchQuery=$event}}})],1),_c('div',{staticClass:"d-flex align-center right-row"},[_c('app-bar-user-notifications'),_c('app-bar-user-menu')],1)])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-links"},[_c('span',[_vm._v("COPYRIGHT © "+_vm._s(new Date().getFullYear())+" "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://www.reworldmedia.com/","target":"_blank"}},[_vm._v("Reworld Media")]),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(", All rights Reserved")])]),_vm._v(" | "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"/privacy-policy"}},[_vm._v("Privacy Policy")]),_vm._v(" | "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"/public-terms-of-service"}},[_vm._v("Public Terms of Service")])])]},proxy:true}])},[_c('v-snackbar',{attrs:{"timeout":_vm.snackBar['timeout'],"color":_vm.snackBar['color'],"top":""},model:{value:(_vm.snackBar['show']),callback:function ($$v) {_vm.$set(_vm.snackBar, 'show', $$v)},expression:"snackBar['show']"}},[_vm._v(" "+_vm._s(_vm.snackBar["text"])+" ")]),_c('v-dialog',{attrs:{"width":_vm.dialog['width']},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return undefined}}]),model:{value:(_vm.dialog['show']),callback:function ($$v) {_vm.$set(_vm.dialog, 'show', $$v)},expression:"dialog['show']"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2",domProps:{"innerHTML":_vm._s(_vm.dialog['title'])}}),_c('v-card-text',[_c('div',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.dialog['text'])}}),_c('pre',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialog['code'] != ''),expression:"dialog['code'] != ''"}],staticStyle:{"width":"100%","overflow-x":"auto"},domProps:{"innerHTML":_vm._s(_vm.dialog['code'])}})]),_c('v-divider'),(_vm.dialog['type'] == 'confirm')?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialog['callbackFunction']();
            _vm.dialog['show'] = false;}}},[_vm._v(" Confirmer ")]),_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialog['show'] = false}}},[_vm._v(" Abandonner ")])],1):_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog['show'] = false}}},[_vm._v(" Fermer ")])],1)],1)],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }